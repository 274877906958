/*eslint-disable */
// import GPathSessionModel from '../../Model/GPathSession'
import request from '../../Utils/curl'
import axios from 'axios';

let GPathSessions = {
  /**
   * gpath_sessionList
   */
  async gpath_sessionList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_session_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_sessionList() and Exception:',err.message)
    }
  },

  /**
   * gpath_sessionView
   */
  async gpath_sessionView (context, gpathSessionId=null, gpathSessionData) {
    try {      
      let post_data = new FormData();

      if(gpathSessionData && gpathSessionData.student_id) {
        post_data.append('student_id', gpathSessionData.student_id);  
      }

      if(gpathSessionId) {
        post_data.append('gps_id', gpathSessionId);
      }
      
      if (gpathSessionData.latest_incomplete_session) {
        post_data.append('latest_incomplete_session', gpathSessionData.latest_incomplete_session);
      }
      
      return await request.curl(context, "gpath_session_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_sessionView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_sessionAdd
   */
  async gpath_sessionAdd (context, gpath_sessionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_sessionObj) {
        post_data.append(key, gpath_sessionObj[key]);
      }

      return await request.curl(context, "gpath_session_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_sessionAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_sessionEdit
   */
  async gpath_sessionEdit (context, gpath_sessionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_sessionObj) {
        post_data.append(key, gpath_sessionObj[key]);
      }

      return await request.curl(context, "gpath_session_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_sessionEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_sessionDelete
   */
  async gpath_sessionDelete (context, gpathSessionId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gps_id', gpathSessionId);

      return await request.curl(context, "gpath_session_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_sessionDelete() and Exception:',err.message)
    }
  },

  /**
   * gpath_session_check_payment
   */
  async gpath_session_check_payment (context, checkObj) {
    try{
      let post_data = new FormData();
      let data = checkObj

      for (let key in data) {
        post_data.append(key, data[key]);
      }
      return await request.curl(context, "gpath_session_check_payment", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_sessionDelete() and Exception:',err.message)
    }
  },

  /**
   * gpath_session_report_generate
   */
  async gpath_session_report_generate (context, data) {
    try{
      let post_data = new FormData();
      
      for (let key in data) {
        post_data.append(key, data[key]);
      }

      let API = process.env.VUE_APP_GIDE_API
      if(process.env.VUE_APP_ENV == "prod" || process.env.VUE_APP_ENV == "uat"){
        API = process.env.VUE_APP_SUBSCRIPTION_API
      }

      return await request.curl(context, "generate_pdf", post_data, API)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_session_report_generate() and Exception:',err.message)
    }
  },
  /**
   * gpath_session_report_generate_lambda
   */
  async gpath_session_report_generate_lambda (context, data) {
    try{
      let api_url = process.env.VUE_APP_GPATH_LAMBDA_URL;
      axios({
        method: "post",
        url: api_url,
        timeout: 1000 * 60 * 5, // Wait for 60*5 seconds
        data: data,
        headers: {
          'Content-Type': 'application/json',
        }
      })

      return
    }
    catch(err){
      console.log('Exception occurred at gpath_session_report_generate() and Exception:',err.message)
    }
  },

   /**
   * gpath_invite
   */
  async gpath_invite (context, gpath_sessionObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_sessionObj) {
        post_data.append(key, gpath_sessionObj[key]);
      }

      return await request.curl(context, "gpath_invite", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_invite() and Exception:',err.message)
    }
  },

  /**
   * gpath_NotesEdit
   */
  async gpathNotesEdit (context, gpath_notesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_notesObj) {
        post_data.append(key, gpath_notesObj[key]);
      }

      return await request.curl(context,"gpath_notes_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_notesEdit() and Exception:',err.message)
    }
  }
}

export {
  GPathSessions
}
